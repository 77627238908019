import FCDTModal from "components/modal-notification/fcdt-modal";
import style from "./style/modal-dates.module.scss";
import Button from "components/buttons/fcdt-button";
import { ModalDatesNotificationProps } from "./model";
import { IoHelpCircleSharp } from "react-icons/io5";
import Title from "components/fcdt-title/fcdt-title";
import { useState } from "react";

const ModalDates = (props: ModalDatesNotificationProps) => {
  const { handleCloseModal, register, handleSubmit, onSubmit } = props;

  const actualDate = new Date();

  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipText, setShowTooltipText] = useState(false);
  const children = (
    <form className={style.process_container} onSubmit={handleSubmit(onSubmit)}>
      <div style={{ position: 'relative', display: 'inline-block' }}>

        {showTooltip && (
          <div
            style={{
              position: 'absolute',
              top: '120%',
              left: '50%',
              background: "#fff",
              transform: 'translateX(-50%)',
              padding: '5px 10px',
              color: '#2F0084',
              border: '1px solid #2F0084',
              borderRadius: '4px',
              whiteSpace: 'nowrap',
            }}
          >
            <span style={{fontSize:"16px"}}>
            Texto de ayuda:{showTooltipText?' La fecha optimista, es la fecha más cercana para entregar la tarea.':
            ' La fecha probable, Es la fecha más racional para entregar la tarea.'}</span>
            <br />
          </div>
        )}
      </div>
      <div className={style.content}>
        <div style={!showTooltip ? { display: "none" } : {}}>
          <p></p>
          <p></p>
        </div>

        <h1 style={showTooltip ? { color: "#fff" } : {}} >Fechas importantes de la tarea</h1>

        <div className={style.content_left}>
          <div>
            <label htmlFor="date_create">
              <Title fontSize={16}>Fecha probable</Title>
              <span>
                <IoHelpCircleSharp
                  onMouseEnter={() => {setShowTooltip(true);
                                      setShowTooltipText(false);}}
                  onMouseLeave={() => setShowTooltip(false)}
                  size={25}
                  className={style.icon_admiration}
                  title="Es la fecha más racional para entregar la tarea"
                />
              </span>
            </label>
            <input
              type="date"
              placeholder="Fecha de creación  Día / Mes / Año"
              min={actualDate.toISOString().split("T")[0]}
              required
              {...register("date_likely")}
            />
          </div>
          <div>
            <label htmlFor="date_create">
              <Title fontSize={16}>Fecha optimista</Title>
              <span
              >
                <IoHelpCircleSharp
                  onMouseEnter={() => {setShowTooltip(true);
                                      setShowTooltipText(true);}}
                  onMouseLeave={() => setShowTooltip(false)}
                  
                  size={25}
                  className={style.icon_admiration}
                  title="Es la fecha más cercana para entregar la tarea"
                />
              </span>
            </label>
            <input
              type="date"
              placeholder="Fecha de creación  Día / Mes / Año"
              min={actualDate.toISOString().split("T")[0]}
              required
              {...register("date_optimistic")}
            />
          </div>

        </div>
      </div>
      <div className={style.content_buttons}>
        <Button
          className="btn_cancel"
          children="Cancelar"
          width="40"
          type="button"
          onClick={handleCloseModal}
        />
        <Button children="Guardar" width="40" type="submit" />
      </div>
    </form>
  );

  return (
    <FCDTModal open={true} children={children} handleClose={handleCloseModal} />
  );
};

export default ModalDates;
