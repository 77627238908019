import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModalAddTaskContainer from "./components/modal-add_task/modal-add-task-container";
import RecomendedTask from "./recomended-task";
import { useDispatch, useSelector } from "react-redux";
import { getColaborators } from "pages/calendar/store/slices/create-event";
import ModalDatesContainer from "pages/collaborator/components/process/components/modal-dates/modal-dates-container";
import ModalAddSubTaskContainer from "./components/modal-add-subtask/modal-add-subtask-container";
import {
  getTaskRecomendation,
  setServiceRecomendedAction,
} from "pages/document-management/store/slices/recomendations";
import { RootState } from "store/store";
import { getAllTaskByIdGoal } from "pages/collaborator/store/slices/process";

const RecomendedTaskContainer = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const { taskRecomended } = useSelector((state: RootState) => {
    return state.documentsManagement.recomendations;
  });

  const { dataAllColaborator } = useSelector((state: RootState) => {
    return state.calendar.createEvent;
  });

  const idProcessGoal = useParams().id_process_goal;
  const idMeeting = useParams().id_meeting;

  useEffect(() => {
    if (idMeeting && dataUser) {
      dispatch(getTaskRecomendation(idMeeting));
    }
  }, [idMeeting, dataUser]);

  const handleNavigate = (id: number) => {
    if (id === 1) {
      navigate(-1);
      return;
    }
  };

  const handleClickService = (id: number, idAction: number, idMeeting:string) => {
    dispatch(setServiceRecomendedAction({ service: id, idAction, idMeeting,old_task_name:""}));

    if (id === 2 && idProcessGoal && dataAllColaborator.length === 0) {
      dispatch(getColaborators(idProcessGoal));
      return;
    }

    if (id === 3) {
      dispatch(getAllTaskByIdGoal(idProcessGoal!,true));
    }
  };

  return (
    <>
      <RecomendedTask
        handleNavigate={handleNavigate}
        handleClickService={handleClickService}
        taskRecomended={taskRecomended}
      />
      <ModalAddTaskContainer />
      <ModalDatesContainer />
      <ModalAddSubTaskContainer />
    </>
  );
};

export default RecomendedTaskContainer;
