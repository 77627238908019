import { IoArrowBackOutline, IoCreateOutline } from "react-icons/io5";
import FilterContainer from "components/filter/filter-container";
import { TableObjetivesUserProps } from "../../model";
import style from "../table-process/styles/table-process.module.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "routes/constants";
import Title from "components/fcdt-title/fcdt-title";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { setisOpenNewProcess, setOpenNewGoal } from "pages/admin/store/slices/process";
import Dialog from "components/modals/dialog";
import { NewGoal } from "components/modals/components/new-goal";
import { GoPlusCircle } from "react-icons/go";

const TableObjetives = (props: TableObjetivesUserProps) => {
  const { data, nameProcess, handleNavigate, pathRef } = props;
  const { isOpenNewProcess, isOpenNewGoal } = useSelector((state: RootState) => {
    return state.admin.process;
  });
  const handleOpenDialogGoal = () => {
  }
  const dispatch = useDispatch<any>();

  return (
    <div>
      <Dialog
        isOpen={isOpenNewGoal}
        onClose={handleOpenDialogGoal}
        styleClose={false}
        customClass={style.content_new_goal}
      >
        <NewGoal />
      </Dialog>
      <section className={style.container_table}>
        <div className={`${style.controls_table} fcdt-flex-box`}>
          <div style={{ marginRight: "140px" }} className={style.toggle_table}>
            <span onClick={() => handleNavigate()}>
              <IoArrowBackOutline className={style.icon} size={25} /> Atrás
            </span>
          </div>
          <FilterContainer category={"actividad"} />
        </div>

        <Title fontSize={18} fontWeight={600} className={style.title_table}>
          Actividades del proceso {nameProcess} {pathRef}
        </Title>
        <Button onClick={() => {
          dispatch(setOpenNewGoal(!isOpenNewProcess));
          dispatch(setisOpenNewProcess(!isOpenNewProcess));
        }}>
        </Button>
        <div className={style.content}>
          <table>
            <thead>
              <tr>
                <th>Nombre de la actividad</th>
                <th>Descripción</th>
                <th className={style.th_level_action}>Prioridad</th>
                <th className={style.th_level_action}>Estado</th>
                <th className={style.th_level_action}>Acción</th>
              </tr>
            </thead>
            <tbody>
              {data.map((event) => (
                <tr key={event.index}>
                  <td>{event.goal_title}</td>
                  <td className={style.td_description}>
                    <div>{event.goal_description}</div>
                  </td>
                  <td>
                    <strong>{event.goal_priority}</strong>
                  </td>
                  <td>En curso </td>
                  <td>
                    <Link
                      className="fcdt-link-to"
                      to={
                        pathRef === ROUTES.DOCUMENT_MANAGEMENT.REF
                          ? `${event.id}/informe`
                          : `${event.id}/tareas`
                      }
                    >
                      <IoCreateOutline className="fcdt-icon fcdt-gray" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ marginLeft: '84%', borderRadius: '12px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => {
            dispatch(setOpenNewGoal(!isOpenNewProcess)); dispatch(setisOpenNewProcess(!isOpenNewProcess));
          }
          }
        >
          <GoPlusCircle size={30} color="#00de97" />
          Actividad
        </div>
      </section>
    </div>
  );
};

export default TableObjetives;
