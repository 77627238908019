import ModalAddSubTask from "./modal-add-subtask";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  setServiceRecomendedAction,
  setSubtaskIntoTask,
} from "pages/document-management/store/slices/recomendations";
import { setSelectTask } from "pages/collaborator/store/slices/process";
import { TasksByGoal } from "pages/collaborator/components/process/model";
import toast from "react-hot-toast";

const ModalAddSubTaskContainer = () => {
  const dispatch: any = useDispatch();

  const { showModalAddSubTask, actionSelected } = useSelector(
    (state: RootState) => {
      return state.documentsManagement.recomendations;
    }
  );

  const { dataAllTaskByIdGoal, taskSelected } = useSelector(
    (state: RootState) => {
      return state.colaborator.process;
    }
  );

  const handleCloseModal = () => {
    dispatch(setServiceRecomendedAction({ service: 3, idAction: NaN ,
      idMeeting:"",old_task_name:""}));
  };

  const handleSelectTask = (task: TasksByGoal) => {
    dispatch(setSelectTask(task));
  };

  const handleSubmit = async () => {
    if (!taskSelected) {
      toast.error("Selecciona una tarea", {duration: 7000, position:"top-center"});
    }

    const res = await dispatch(setSubtaskIntoTask());

    if (!res?.ok) {
      toast.error(res?.msg, {duration: 7000, position:"top-center"});
      return;
    }

    dispatch(
      setServiceRecomendedAction({
        service: 1,
        idAction: actionSelected ? actionSelected.id : NaN, 
        idMeeting:"",old_task_name:""
      })
    );
    handleCloseModal();
    toast.success("Subtarea asignada exitosamente", {duration: 7000, position:"top-center"});
  };

  return (
    <ModalAddSubTask
      openModal={showModalAddSubTask}
      handleCloseModal={handleCloseModal}
      actionSelected={actionSelected ? actionSelected.task_name : ""}
      listTask={dataAllTaskByIdGoal}
      handleSelectTask={handleSelectTask}
      taskSelected={taskSelected}
      handleSubmit={handleSubmit}
    />
  );
};

export default ModalAddSubTaskContainer;
