import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { displayLoader, removeProcess } from "store/slices/loading";
import { AppThunk } from "store/store";
import * as Api from "pages/document-management/api/api";
import {
  ResponseTaskRecomended,
  TaskRecomended,
} from "pages/document-management/components/documents/components/recommended-task/model";
import toast from "react-hot-toast";

type InitialState = {
  taskRecomended: TaskRecomended[];
  deleteTaskRecomended: TaskRecomended[];
  showModalAddTask: boolean;
  showModalAddSubTask: boolean;
  actionSelected?: TaskRecomended;
};

const initialState: InitialState = {
  taskRecomended: [],
  deleteTaskRecomended: [],
  showModalAddTask: false,
  showModalAddSubTask: false,
  actionSelected: undefined,
};
export const slice = createSlice({
  name: "recomendation",
  initialState,
  reducers: {
    setTaskRecomended: (state, action: PayloadAction<string[]>) => {
      state.taskRecomended = action.payload.map((item, index) => {
        return { id: index + 1, task_name: item, old_task_name:item};
      });
    },
    setServiceRecomendedAction: (
      state,
      action: PayloadAction<{ service: number; idAction: number, idMeeting: string, old_task_name:any}>
    ) => {
      const findAction = state.taskRecomended.find(
        (item) => item.id === action.payload.idAction
      );
      //  Eliminacion de acciones recomendadas
      if (action.payload.service === 1) {
        state.deleteTaskRecomended = state.taskRecomended.filter(
          (item) => item.id === action.payload.idAction
        );
        state.taskRecomended = state.taskRecomended.filter(
          (item) => item.id !== action.payload.idAction
        );
      }
      // Crear tareas con las acciones recomendadas
      else if (action.payload.service === 2) {
        if (state.showModalAddTask) {
          state.actionSelected = undefined;
        } else {
          state.actionSelected = findAction;
          state.deleteTaskRecomended = state.taskRecomended.filter(
            (item) => item.id === action.payload.idAction
          );
        }
        state.showModalAddTask = !state.showModalAddTask;
      }
      // Crear subtareas con las acciones recomendadas 
      else if (action.payload.service === 3) {
        if (state.showModalAddSubTask) {
          state.actionSelected = undefined;
        } else {
          state.actionSelected = findAction;
          state.deleteTaskRecomended = state.taskRecomended.filter(
            (item) => item.id === action.payload.idAction
          );
        }
        state.showModalAddSubTask = !state.showModalAddSubTask;
      }
      // Actualizacion de acciones recomendadas segun lo que haya digitado el usuario
      else if (action.payload.service === 4) {
        state.deleteTaskRecomended = [{
          "id": action.payload.idAction,
          "task_name": action.payload.idMeeting,
          "old_task_name":action.payload.old_task_name
        }]
      }
    },
  },
});

export const { setServiceRecomendedAction } = slice.actions;

export default slice.reducer;

export const updateTaskRecomendation = (idMeeting: string,updateTask:boolean): AppThunk =>
  async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    const authState = getstate().auth;
    const recomendationsState = getstate().documentsManagement.recomendations;
    if (!authState?.dataUser?.token) {
      toast.error("El token no está definido.", { duration: 7000, position: "top-center" });
      dispatch(removeProcess(idProcess));
      return;
    }
    const token = authState.dataUser.token;
    if (!recomendationsState?.deleteTaskRecomended?.length) {
      toast.error("No hay tareas recomendadas para eliminar.", { duration: 7000, position: "top-center" });
      dispatch(removeProcess(idProcess));
      return;
    }
    const taskName = recomendationsState?.deleteTaskRecomended[0]?.task_name || "";
    const oldTaskName = recomendationsState?.deleteTaskRecomended[0]?.old_task_name || "";
    if (!idMeeting || !taskName) {
      toast.error("Datos inválidos para la llamada a la API.", { duration: 7000, position: "top-center" });
      dispatch(removeProcess(idProcess));
      return;
    }
    try {
      await Api.updateTaskRecomendation(idMeeting, taskName, token, updateTask,oldTaskName);
      toast.success("Recomendación actualizada exitosamente", { duration: 7000, position: "top-center" });
      dispatch(removeProcess(idProcess));
      return true;
    } catch (error) {

      toast.error("Error al actualizar la tarea recomendada:" + error, { duration: 7000, position: "top-center" });
      dispatch(removeProcess(idProcess));
      return false;
    }
  };

export const getTaskRecomendation =
  (idMeeting: string): AppThunk =>
    async (dispatch, getstate) => {
      const idProcess: string = dispatch(displayLoader());
      try {
        const { token } = getstate().auth.dataUser!;
        const response: ResponseTaskRecomended = await Api.getTaskRecomendation(
          idMeeting,
          token
        );
        dispatch(slice.actions.setTaskRecomended(response.data.commitments));
        dispatch(removeProcess(idProcess));
        return true;
      } catch (error) {
        console.log({ error });
        dispatch(removeProcess(idProcess));
        return false;
      }
    };

export const setSubtaskIntoTask =
  (): AppThunk => async (dispatch, getstate) => {
    const idProcess: string = dispatch(displayLoader());
    try {
      const { token } = getstate().auth.dataUser!;
      const { taskSelected } = getstate().colaborator.process;
      const { actionSelected } = getstate().documentsManagement.recomendations;

      const response = await Api.setSubtaskIntoTask(
        taskSelected?.uid!,
        actionSelected?.task_name!,
        token
      );
      dispatch(removeProcess(idProcess));
      return response;
    } catch (error) {
      console.log({ error });
      dispatch(removeProcess(idProcess));
      return false;
    }
  };
